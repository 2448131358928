import React, { Component } from 'react';

class Book extends Component {

  constructor(props) {
    // Call super class
    super(props);
    this.state = {
    	title: "Default Title", //set initial state to prevent null in render before load
      	author: "Default Author",
      	imgurl: "defaultImageURL.jpg",
      	summary: "summary"
    }
  }
  render() {
  	this.state.title = this.props.title;
    this.state.author = this.props.author;
    this.state.imgurl = this.props.imgurl;
    this.state.summary = this.props.summary;
    // Your render function
    return <div>
      <div className="book-information">
      		<div className="book-image"><img alt={this.state.title} src={this.state.imgurl} /></div>
				<h1>{JSON.stringify(this.state.title)}</h1>
				<div id="author">By {JSON.stringify(this.state.author)}</div>
			</div>
			<div className="summaries">
				<div className="summary">
					<h2 className="char-title">Short summary (150 characters)</h2>
					<div className="arrow-wrap">
						<div className="upa">▲</div>
						<div className="doa">▼</div>
					</div>
					<p className="csum">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis.</p>
				</div>
				<div className="summary">
					<h2 className="char-title">Summary</h2>
					<div className="arrow-wrap">
						<div className="upa">▲</div>
						<div className="doa">▼</div>
					</div>
					<p className="csum">{this.state.summary}</p>
				</div>
			</div>
    </div>
  }
}

export default Book;