import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import * as serviceWorker from './serviceWorker';
import Archive from './components/Archive';
import Single from './components/Single';
import Header from './components/Header';



loadData();
var data = "";
const routes = [];
function loadData(){
    var csvFilePath = require("./data/combined.txt");    
    var Papa = require("papaparse/papaparse.min.js");
    Papa.parse(csvFilePath, {
      header: true,
      delimiter: "\t",
      download: true,
      skipEmptyLines: true,
      quotes: true,
      // Here this is also available. So we can call our custom class method
      complete: updateData
    });  
}
function updateData(result) {
  data = result.data;
}
createAbcArray(data);
function createAbcArray() {
  var abcArray = [];
  function genCharArray(charA, charZ) {
      var a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
      for (; i <= j; ++i) {
          a.push(String.fromCharCode(i));
      }
      return a;
  }
  abcArray = genCharArray('a', 'z'); // ["a", ..., "z"]

  for (var i = 0; i < abcArray.length; i++) {
    var rte = {};
    var ltr = abcArray[i].toString();
    //console.log("ltr: " + ltr);
    rte.path = "/"+ltr;
    rte.component = () => (
        <Archive data={data} /> // not working
      );
    routes.push(rte);

  }
  rte.path = "/";
  rte.component = () => (
    <Archive data={data} /> // not working
   );
    routes.push(rte);
}
const routeComponents = routes.map(({path, component}, key) => <Route exact path={path} component={component} key={key} />);
ReactDOM.render(<Router>
        <Header/> 
        <Switch>
          <Route exact path="/" component = {() => <Archive data={data} />} 
          />
          {routeComponents}          
          <Route component={Single} />
        </Switch>
      </Router>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
