import React from 'react';
import { Link } from 'react-router-dom';

class Archive extends React.Component {
  

  constructor(props) {
    // Call super class
    super(props);
    this.state = {
      data: this.props.data
    }
    console.log("props: " + JSON.stringify(this.props));
  }
  render() {
    // Your render function
      var NavLinks = [];
      var currentLocation = window.location.pathname;      
      for (var ii = 0; ii < this.state.data.length; ii++) {
        var mTitle = (this.state.data[ii].title + "").replace("#",""); 
        var mAuthor = (this.state.data[ii].author + "").replace("#","");
        var mSummary = (this.state.data[ii].summary + "").replace("#","");
        //console.log("mSummary: " + mSummary);
        var imgUrl = this.state.data[ii].small_image_url;
        var path = slugify(this.state.data[ii].author + "") + "/" + slugify(this.state.data[ii].title + "");
        var slug = path;

        console.log("mTitle.charAt(0): " + mTitle.charAt(0));
        console.log("currentLocation.replace(): " + currentLocation.replace("/",""));
         if ((mTitle.toLowerCase().charAt(0)===currentLocation.replace("/","")) || (currentLocation.replace("/","")==""))  {
        NavLinks.push(<div key={ii}>
          <Link className="archive-item" to={{ pathname: slug, state: { title: mTitle, author: mAuthor, imgurl: imgUrl, summary: mSummary} }}>
            {mTitle}
            </Link> by {this.state.data[ii].author} </div>);
        }
      }
      return <div><div id="body"><h1>{currentLocation}</h1>{NavLinks}</div></div>
      function slugify(text)
      {
        return text.toString().toLowerCase()
          .replace(/\s+/g, '-')           // Replace spaces with -
          .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
          .replace(/\-\-+/g, '-')         // Replace multiple - with single -
          .replace(/^-+/, '')             // Trim - from start of text
          .replace(/-+$/, '')            // Trim - from end of text
          .replace('the','')
          .replace('--','-');          
      }        
  }
}
export default Archive;