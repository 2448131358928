import React from 'react';
import '../App.css';
import Book from './Book';

var the_title = "";
var the_author = "";
var img_url = "";
var the_summary = "";

class Single extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "null",
            title: "title",
            author: "author",
            imgurl: "imgurl",
            summary: "summary"
        }        
    }
    render() {     
        if(this.props.location.state) {
            the_title = this.props.location.state.title + "";
            the_author = this.props.location.state.author + "";   
            img_url = this.props.location.state.imgurl + "";        
            the_summary = this.props.location.state.summary + "";
            /*
            the_summary = the_summary.split('.').map((item, i) => {
                return <p key={i}>{item}</p>;
            });
            */
        }              
        return <div id = "body" >
            <Book title={the_title} author={the_author} imgurl={img_url} summary={the_summary} />			 
        </div>
    }
}
export default Single;