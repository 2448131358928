import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

var abcdArray = [];
function genCharArray(charA, charZ) {
    var a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
        a.push(String.fromCharCode(i));
    }
    return a;
}
abcdArray = genCharArray('a', 'z'); // ["a", ..., "z"]

class Menu extends React.Component {

  render() {
    var NavLinks = [];
	for (var i = 0; i < abcdArray.length; i++) {
	  NavLinks.push(<Link key={i} to={'../'+abcdArray[i]}>{abcdArray[i].toUpperCase()}</Link>);
	}
	return <div id="page-menu">{NavLinks}</div>
  }
}
export default Menu;
