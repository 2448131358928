import React from 'react';
import '../App.css';
import Menu from './Menu';
import { Link } from 'react-router-dom'

class Header extends React.Component {
  render() {
  	 return <div className="header-wrap">
      <header className="App-header">
      	<Link to="/" style={{ textDecoration: 'none' }}>
        	<div id="identity">
            <span id="pepper-logo">🌶</span> 
            <div>
              <span id="wordmark">Bookpepper</span>
              <div id="tagline">Bite-sized book summaries</div>
            </div>
          </div>
    	</Link>
      </header>
      <Menu />
    </div>
  }
}

export default Header;
